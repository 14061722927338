import * as React from "react"

import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import "../styles/main.scss"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>Diese Seite gibt es nicht</p>
  </Layout>
)

export default NotFoundPage
